<template>
    <svg width="92" height="23" viewBox="0 0 92 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_329)">
    <mask id="mask0_1_329" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="1" width="92" height="22">
    <path d="M91.21 1.14627H0V22.2163H91.21V1.14627Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_1_329)">
    <path d="M19.64 12.8463L16.9 5.70627H13.8L18.37 16.1763L19.64 12.8563V12.8463ZM25.94 5.69627C25.35 5.69627 24.85 5.77627 24.44 5.94627C24.03 6.10627 23.68 6.35627 23.38 6.67627C23.08 6.99627 22.82 7.39627 22.59 7.85627C22.36 8.32627 18.46 18.7063 18.46 18.7063C18.27 19.1563 18.07 19.4863 17.84 19.6863C17.57 19.9163 17.19 20.0363 16.73 20.0363C16.51 20.0363 16.28 20.0163 16.05 19.9863C15.82 19.9563 15.6 19.8963 15.39 19.8263L14.9 21.8663C15.31 21.9963 15.68 22.0763 16.03 22.1363C16.37 22.1863 16.75 22.2163 17.16 22.2163C17.75 22.2163 18.25 22.1363 18.66 21.9663C19.07 21.8063 19.42 21.5563 19.72 21.2363C20.02 20.9163 20.28 20.5163 20.51 20.0563C20.74 19.5863 24.55 9.61627 24.71 9.20627C24.87 8.79627 25.1 8.42627 25.33 8.22627C25.6 7.99627 25.98 7.87627 26.44 7.87627C26.66 7.87627 26.36 5.69627 25.95 5.69627H25.94Z" fill="#6899A4"/>
    <path d="M46.98 12.4563V11.2863C46.98 10.3963 46.87 9.58626 46.65 8.85626C46.43 8.12626 46.09 7.50626 45.64 6.97626C45.19 6.45626 44.62 6.04626 43.93 5.75626C43.24 5.46626 42.43 5.32626 41.49 5.32626C40.61 5.32626 39.81 5.46626 39.09 5.75626C38.36 6.04626 37.74 6.46626 37.21 7.02626C36.69 7.58626 36.28 8.26626 35.99 9.07626C35.7 9.88626 35.56 10.8163 35.56 11.8663C35.56 13.8963 36.06 15.4763 37.07 16.5963C38.08 17.7163 39.55 18.2763 41.49 18.2763C43.1 18.2763 44.34 17.9263 45.2 17.2363C46.06 16.5363 46.6 15.5563 46.82 14.2963H44.24C44.1 14.9063 43.82 15.3763 43.4 15.7063C42.98 16.0363 42.34 16.1963 41.5 16.1963C40.42 16.1963 39.62 15.8563 39.08 15.1663C38.55 14.4763 38.27 13.5763 38.24 12.4663C38.24 12.4663 38.24 12.1863 38.24 11.5863C38.24 10.9863 38.29 10.6063 38.29 10.6063C38.37 9.61626 38.67 8.83626 39.18 8.24626C39.7 7.65626 40.45 7.36626 41.43 7.36626C42.41 7.36626 43.21 7.65626 43.68 8.23626C44.15 8.81626 44.38 9.60626 44.38 10.6063H39.78V12.4563H46.99H46.98Z" fill="#6899A4"/>
    <path d="M2.79 4.49627H0V17.8963H2.79V4.49627ZM9.36 10.5763C10.29 10.1463 11.03 9.51627 11.45 8.76627C11.87 8.01627 12.08 7.10627 12.08 6.04627C12.08 5.46627 11.99 4.88627 11.81 4.29627C11.63 3.70627 11.3 3.18627 10.82 2.71627C10.34 2.24627 9.69 1.86627 8.87 1.57627C8.05 1.28627 7 1.14627 5.71 1.14627H0V3.49627H2.79H5.65C6.35 3.49627 6.93 3.55627 7.39 3.68627C7.84 3.81627 8.2 3.99627 8.47 4.22627C8.74 4.45627 8.92 4.73627 9.03 5.05627C9.14 5.37627 9.19 5.72627 9.19 6.09627C9.19 6.51627 9.14 6.90627 9.04 7.24627C8.94 7.58627 8.77 7.88627 8.52 8.12627C8.27 8.36627 7.94 8.55627 7.52 8.68627C7.1 8.81627 6.57 8.88627 5.93 8.88627H3.81V11.2763H5.79C5.96 11.2763 6.12 11.2663 6.27 11.2663C7.33 11.2663 8.43 10.9963 9.35 10.5763H9.36Z" fill="#6899A4"/>
    <path d="M9.77 11.3963C9.65 11.4663 9.52 11.5263 9.39 11.5863C8.75 11.8763 7.9 12.0863 7.19 12.1963L9.76 17.8963H12.86L9.78 11.3963H9.77Z" fill="#6899A4"/>
    <path d="M31.89 15.9563C30.87 15.9563 30.37 15.3663 30.37 14.1763V7.88627H31.64L32.64 6.74627L31.59 5.69627H30.38V2.40627H27.71V5.68626H25.93V7.87627H27.71V13.9363C27.71 15.3163 27.98 16.3563 28.52 17.0563C29.06 17.7563 30 18.1163 31.35 18.1163C32.23 18.1163 33.04 17.9863 33.79 17.7363L33.39 15.7463C32.97 15.8863 32.48 15.9563 31.91 15.9563H31.89Z" fill="#6899A4"/>
    <path d="M33.03 3.03627L31.56 4.50627L33.78 6.74627L31.56 9.09627L33.03 10.4163L36.52 6.74627L33.03 3.03627Z" fill="#6899A4"/>
    <path d="M64.3 1.14627H65.66V17.8963H64.3V1.14627Z" fill="#010101"/>
    <path d="M49.99 17.8963H51.4V1.14627H49.99V17.8963ZM61.15 4.11627C60.98 3.52627 60.67 3.01627 60.22 2.57627C59.77 2.13627 59.16 1.78627 58.38 1.53627C57.6 1.28627 56.6 1.14627 55.38 1.14627H52.4V2.38627H55.34C56.25 2.38627 57 2.45627 57.59 2.60627C58.18 2.75627 58.66 2.98627 59.01 3.28627C59.36 3.59627 59.61 3.96627 59.75 4.42627C59.89 4.87627 59.96 5.41627 59.96 6.04627C59.96 6.67627 59.9 7.17627 59.77 7.64627C59.64 8.11627 59.41 8.51627 59.07 8.84627C58.73 9.17627 58.26 9.42627 57.66 9.60627C57.07 9.78627 56.3 9.87627 55.36 9.87627H52.4V11.1163H55.34C56.56 11.1163 57.56 10.9763 58.34 10.7063C59.12 10.4363 59.74 10.0563 60.19 9.59627C60.64 9.13627 60.96 8.57627 61.14 7.95627C61.32 7.32627 61.41 6.67627 61.41 5.98627C61.41 5.29627 61.32 4.70627 61.15 4.11627Z" fill="#010101"/>
    <path d="M77.83 8.03627C77.67 7.48627 77.42 7.01627 77.07 6.62627C76.72 6.24627 76.25 5.94627 75.67 5.74627C75.09 5.54627 74.38 5.44627 73.54 5.44627C70.82 5.44627 69.24 6.43627 68.8 8.40627L70.04 8.59627C70.12 8.29627 70.24 8.02627 70.39 7.78627C70.55 7.54627 70.76 7.33627 71.02 7.15627C71.29 6.97627 71.62 6.83627 72.02 6.74627C72.42 6.65627 72.91 6.60627 73.49 6.60627C74.68 6.60627 75.5 6.87627 75.96 7.42627C76.42 7.97627 76.65 8.84627 76.65 10.0263L76.67 11.5463V13.2563C76.67 13.6363 76.65 13.9563 76.6 14.2463C76.55 14.5363 76.47 14.7863 76.35 15.0063C76.23 15.2363 76.08 15.4363 75.9 15.6263C75.72 15.8163 75.49 16.0063 75.21 16.2163C74.88 16.4863 74.46 16.6763 73.96 16.7863C73.45 16.9063 72.94 16.9663 72.42 16.9663C71.39 16.9663 70.66 16.7463 70.25 16.2963C69.84 15.8463 69.63 15.2563 69.63 14.5263C69.63 14.0463 69.71 13.6263 69.88 13.2663C70.04 12.9163 70.31 12.6163 70.69 12.3763C71.07 12.1363 71.56 11.9463 72.17 11.8163C72.78 11.6863 73.54 11.6063 74.45 11.5663L75.66 11.5163V10.4363L74.31 10.4863C73.53 10.5163 72.77 10.5963 72.05 10.7063C71.32 10.8263 70.67 11.0363 70.1 11.3263C69.53 11.6263 69.07 12.0263 68.73 12.5463C68.39 13.0663 68.21 13.7263 68.21 14.5363C68.21 15.7363 68.59 16.6463 69.36 17.2563C70.13 17.8663 71.14 18.1763 72.41 18.1763C72.93 18.1763 73.4 18.1263 73.83 18.0163C74.26 17.9063 74.65 17.7563 75.01 17.5663C75.37 17.3763 75.69 17.1663 75.97 16.9163C76.25 16.6763 76.49 16.4263 76.7 16.1763V17.8863H78.06V9.93627C78.06 9.21627 77.98 8.57627 77.83 8.02627V8.03627Z" fill="#010101"/>
    <path d="M81.33 17.8963H82.69V5.81626H81.33V17.8963ZM90.22 6.58626C89.56 5.81626 88.55 5.43626 87.17 5.43626C86.33 5.43626 85.53 5.61626 84.79 5.98626C84.41 6.17626 84.05 6.41626 83.69 6.72626V8.17626C83.82 8.04626 83.95 7.91626 84.1 7.78626C84.52 7.39626 85 7.10626 85.52 6.92626C86.04 6.74626 86.54 6.65626 87.01 6.65626C88 6.65626 88.71 6.93626 89.17 7.48626C89.62 8.04626 89.85 8.95626 89.85 10.2163V17.8863H91.21V9.83626C91.21 8.42626 90.88 7.34626 90.22 6.57626V6.58626Z" fill="#010101"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_1_329">
    <rect width="92" height="22" fill="white" transform="translate(0 0.736267)"/>
    </clipPath>
    </defs>
    </svg>
</template>
<script>
export default {
    name: 'LogoGreenBlack',
}
</script>

<template>
  <FilterableTable></FilterableTable>
</template>
 
<script>
import FilterableTable from './FilterableTable.vue'

export default {
  name: "HomeView",
  components: {
    FilterableTable,
  },
  setup() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  data() {
    return {
    }
  },
  methods: {
  }
};
</script>

<style>


</style>
<template>
  <FilterableTable></FilterableTable>
  <!-- <BrowseAndSearch></BrowseAndSearch> -->
</template>
 
<script>
import FilterableTable from './FilterableTable.vue'
// import BrowseAndSearch from './BrowseAndSearch.vue'

export default {
  name: "HomeView",
  components: {
    FilterableTable,
    // BrowseAndSearch
  },
  setup() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  data() {
    return {
    }
  },
  methods: {
  }
};
</script>

<style>


</style>
export const affilList = [
  "Church of Christ",
  "Church of the New Jerusalem",
  "United Methodist Church",
  "Presbyterian Church (U.S.A.)",
  "American Baptist Churches in the U.S.A.",
  "Church of God",
  "Brethren Church",
  "Christian Church (Disciples of Christ)",
  "Evangelical Lutheran Church in America",
  "Baptist General Association of Virginia",
  "Wesleyan Church",
  "Assemblies of God",
  "Missionary Church",
  "Mennonite Church USA",
  "Baptist General Conference",
  "Church of the Brethren",
  "The Church of Jesus Christ of Latter-day Saints",
  "Kentucky Baptist Convention",
  "North Carolina Baptist State Convention",
  "The Christian and Missionary Alliance",
  "Southern Baptist Convention",
  "United Church of Christ",
  "Roman Catholic Church",
  "Christian Churches and Churches of Christ",
  "Pentecostal Assemblies of Canada",
  "Reformed Church in America",
  "Lutheran Church-Missouri Synod",
  "Presbyterian Church",
  "Presbyterian Church in America",
  "Roman Catholic Church (Jesuit order)",
  "Baptist General Convention of Texas",
  "Wisconsin Evangelical Lutheran Synod",
  "Society of Friends",
  "Church of the Nazarene",
  "Pentecostal Holiness Church",
  "Associate Reformed Presbyterian Church",
  "Open Bible Standard Churches",
  "General Association of Regular Baptist Churches",
  "Baptist Church",
  "Mennonite Brethren Church",
  "Reformed Presbyterian Church of North America",
  "Grace Gospel Fellowship",
  "Fellowship of Grace Brethren Churches",
  "Methodist Church",
  "Church of the United Brethren in Christ",
  "Christian Methodist Episcopal Church",
  "International Church of the Foursquare Gospel",
  "African Methodist Episcopal Zion Church",
  "Evangelical Friends Church-Eastern Region",
  "Moravian Church",
  "Baptist Educational and Missionary Convention of South Carolina",
  "Lutheran Church",
  "Evangelical Covenant Church",
  "Congregational Christian Church",
  "Free Methodist Church of North America",
  "Evangelical Lutheran Church",
  "Evangelical Free Church of America",
  "Advent Christian Church",
  "African Methodist Episcopal Church",
  "Pentecostal Church",
  "Full Gospel World Mission",
  "Jewish faith",
  "Assembly of God Church",
  "Evangelical Christian Church",
  "Apostolic Church of Pentecost",
  "Muslim faith"
];

<template>
  <v-container>
    <h2>List out the account name and stuff</h2>    
  </v-container>
</template>
 
<script>

export default {
  setup() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  data() {
    return {
    }
  },
  methods: {
  }
};
</script>

<style>


</style>